<template>
    <header>
		<div class="preloader" v-if="loading">
			<div class="circular-spinner">
				<img src="../assets/images/shiba-loading.png" width="150">
			</div>
		</div>
		<nav class="x-header navbar navbar-expand -logged py-0">
			<div class="container-fluid align-items-center h-100 position-relative">
				<div id="headerBrand">
				<!-- <button
					type="button"
					class="btn bg-transparent p-0 x-hamburger js-hamburger-toggle"
					@click="gotoMain()"
				>
					<span></span>
					<span></span>
					<span></span>
				</button> -->
				<div class="-branding-inner-wrapper">
					<router-link to="/" class="navbar-brand">
					<img
						class="-logo"
						src="../assets/images/logo-headline65x400.png"
						alt="DeltaPlus รวมคาสิโนออนไลน์ บาคาร่าสด สล็อต กีฬา และเครดิตฟรี"
					/>
					</router-link>
				</div>
				</div>

				<div id="headerContent">
					<div class="x-ranking-entrance">
							<router-link to="/rewards" class="-link-wrapper">
								<div class="-image-wrapper">
									<img class="img-fluid -ic-right-star-rank" src="../assets/images/coin1.png" alt="PUG888 แลกของรางวัล">
								</div>
								<div class="-text-wrapper">{{numberWithCommas2(user.coin)}}</div>
							</router-link>
					</div>
				<ul class="nav -menu-wrapper -logged">
					<li class="nav-item">
					<router-link to="/play" class="nav-link -partner">
						<div class="-img-wrapper">
						<img
							src="../assets/images/menu/play-1.png"
							class="-icon"
							alt="เข้าเล่น"
							width="60"
							height="60"
						/>
						</div>
						<div class="-text">เข้าเล่น</div>
					</router-link>
					</li>

					<li class="nav-item">
					<router-link to="/promotion" class="nav-link -partner">
						<div class="-img-wrapper">
						<img
							src="../assets/images/menu/promotion-1.png"
							class="-icon"
							alt="Promotion"
							width="60"
							height="60"
						/>
						</div>
						<div class="-text">โปรโมชั่น</div>
					</router-link>
					</li>

					<li class="nav-item">
					<router-link to="/partner" class="nav-link -partner">
						<div class="-img-wrapper">
						<img
							src="../assets/images/menu/friend-1.png"
							class="-icon"
							alt="Partner"
							width="60"
							height="60"
						/>
						</div>
						<div class="-text">พันธมิตร</div>
					</router-link>
					</li>

					<li class="nav-item">
					<router-link to="/cashback" class="nav-link -partner">
						<div class="-img-wrapper">
						<img
							src="../assets/images/menu/money-1.png"
							class="-icon"
							alt="Cashback"
							width="60"
							height="60"
						/>
						</div>
						<div class="-text">คืนยอด</div>
					</router-link>
					</li>
				</ul>

				<div class="navbar-nav">
					<div class="x-logged">
					<div class="-deposit-container d-none d-lg-block">
						<a
						href="#deposit"
						class="text-white js-account-approve-aware btn -btn-deposit"
						data-toggle="modal"
						data-target="#depositModal"
						@click="checkPromotions()"
						>
						<div class="f-7">ฝากเงิน</div>
						</a>
					</div>
					<div class="-withdraw-container d-none d-lg-block">
						<a
						href="#withdraw"
						class="text-white js-account-approve-aware btn -btn-withdraw"
						data-toggle="modal"
						data-target="#withdrawModal"
						@click="moveCreditToWallet()"
						>
						<div class="f-7">ถอนเงิน</div>
						</a>
					</div>
					<div class="-profile-container">
						<div class="d-none d-lg-block">
						<div class="-btn-wrapper">
							<div class="-inner-wrapper">
							<a
								href="#account"
								data-toggle="modal"
								data-target="#accountModal"
								class="text-decoration-none"
							>
								<div class="-profile-name2">{{user.code}}</div>
							</a>
							<div class="-balance-container">
								<div class="-text-username">
								{{user.code}}
								</div>

								<div class="-user-balance js-user-balance f-sm-6 f-7 ">
								<div class="-inner-box-wrapper2">
									<img
									class="img-fluid -ic-coin"
									src="../assets/images/shiba888-ic-coin.png"
									alt="customer image"
									width="26"
									height="21"
									/>

									<span id="customer-balance"
									><span class="-amount">{{ numberWithCommas(user.credit) }}</span>
									</span>
								</div>
								<button
									type="button"
									class="-btn-balance2"
									id="btn-customer-balance-reload"
									@click="reloadBalance()"
								>
									<i :class="{'fas':true, 'fa-sync-alt':true, 'f-10':true, 'fa-spin':fa_spin}"></i>
								</button>
								</div>
							</div>
							</div>

							<a
							href="#account"
							data-toggle="modal"
							data-target="#accountModal"
							>
							<div class="x-profile-image">
								<img
								class="img-fluid -profile-image"
								:src="getLevelImage()"
								alt="customer image"
								style="width:65px"
								/>
							</div>
							</a>
						</div>
						</div>

						<div class="d-lg-none">
						<div :class="{'js-ez-logged-sidebar':true, '-btn-mobile-wrapper':true , '-open':this.accountOpen }">
							<div class="-inner-wrapper" style="line-height:1;">
							<a href="javascript:void(0)" @click="showTabAccount()">
								<div class="-profile-name2">{{user.code}}</div>
							</a>
							<div class="-balance-container">
								<div class="-text-username">
								{{user.tel}}
								</div>

								<div class="-user-balance js-user-balance f-sm-6 f-7 ">
								<div class="-inner-box-wrapper2">
									<img
									class="img-fluid -ic-coin"
									src="../assets/images/shiba888-ic-coin.png"
									alt="customer image"
									width="26"
									height="21"
									/>

									<span id="customer-balance"
									><span class="-amount">{{ numberWithCommas(user.credit) }}</span>
									</span>
								</div>
								<button
									type="button"
									class="-btn-balance2"
									@click="reloadBalance()"
								>
									<i :class="{'fas':true, 'fa-sync-alt':true, 'f-10':true, 'fa-spin':fa_spin}"></i>
								</button>
								</div>
							</div>
							</div>

							<a href="javascript:void(0)" @click="showTabAccount()">
							<div class="x-profile-image">
								<img
								class="img-fluid -profile-image"
								:src="getLevelImage()"
								alt="customer image"
								style="width: 65px;"
								/>
							</div>
							</a>
						</div>

						<div :class="{'x-menu-account-list-sidebar':true,'-open':this.accountOpen}">
							<div class="x-modal-account-menu-mobile">
							<div class="-modal-profile-mobile d-xl-none d-block">
								<div class="text-right">
								<i class="fas fa-times f-5 js-close-account-sidebar" @click="showTabAccount()"></i>
								</div>

								<div class="x-profile-image">
								<img
									class="img-fluid"
									:src="getLevelImage2()"
									alt="customer image"
									style="width:120px;"
								/>
								</div>
								<div class="-balance-container">
								<div class="-text-username">
									<ul class="tags">
										<li><a href="#" class="tag" style="color:#999;">Level {{(user.level)?user.level:1}}</a></li>
									</ul>
								</div>
								<div class="-text-username">
									{{user.tel}}
								</div>

								<div
									class="-user-balance js-user-balance f-sm-6 f-7 "
								>
									<div class="-inner-box-wrapper2">
									<img
										class="img-fluid -ic-coin"
										src="../assets/images/shiba888-ic-coin.png"
										alt="customer image"
										width="26"
										height="21"
									/>

									<span id="customer-balance"
										><span class="-amount">{{numberWithCommas(user.credit)}}</span>
									</span>
									</div>
									<button
									type="button"
									class="-btn-balance2"
									@click="reloadBalance()"
									>
									<i :class="{'fas':true, 'fa-sync-alt':true, 'f-10':true, 'fa-spin':fa_spin}"></i>
									</button>
								</div>
								</div>
							</div>

							<div class="-lists-outer-wrapper">
								<ul class="navbar-nav">
								<!-- <li class="nav-item -join-promotion" v-if="!user.userId">
									<button
									type="button"
									class="nav-link js-close-account-sidebar "
									 @click="lineLogin()"
									>
									<img
										class="img-fluid -icon-image"
										src="../assets/images/icon-line.svg"
										alt="Line"
									/>
									<span class="-text-menu">เข้าสู่ระบบด้วยไลน์</span>
									</button>
								</li>	 -->
								<li class="nav-item -account-profile">
									<button
									type="button"
									class="nav-link js-close-account-sidebar "
									data-toggle="modal"
									data-target="#accountModalMobile"
									>
									<img
										class="img-fluid -icon-image"
										src="../assets/images/shiba888-ic-menu-user.png"
										alt="ic-menu-user"
									/>
									<span class="-text-menu">ข้อมูลบัญชี</span>
									</button>
								</li>
								<!-- <li class="nav-item -coupon">
									<button
									type="button"
									class="nav-link js-close-account-sidebar js-account-approve-aware"
									data-toggle="modal"
									data-target="#couponModalMobile"
									>
									<img
										class="img-fluid -icon-image"
										src="../assets/images/shiba888-ic-menu-coupon.png"
										alt="ic-menu-coupon"
									/>
									<span class="-text-menu">ใช้คูปอง</span>
									</button>
								</li> -->
								<li class="nav-item -join-promotion">
									<button
									type="button"
									class="nav-link js-close-account-sidebar "
									@click="$router.replace('/promotion')"
									>
									<img
										class="img-fluid -icon-image"
										src="../assets/images/shiba888-ic-menu-promotion.png"
										alt="ic-menu-promotion"
									/>
									<span class="-text-menu"
										>โปรโมชั่น</span
									>
									</button>
								</li>
								<li class="nav-item -logout">
									<a
									class="nav-link js-require-confirm"
									data-title="ต้องการออกจากระบบ ?"
									@click="confirmLogout()"
									>
									<img
										class="img-fluid -icon-image"
										src="../assets/images/shiba888-ic-menu-logout.png"
										alt="ic-menu-logout"
									/>
									<span class="-text-menu">ออกจากระบบ</span>
									</a>
								</li>
								</ul>
								<ul class="navbar-nav -action-nav">
								<li class="nav-item">
									<a
									href="https://lin.ee/Tos0Vpg"
									class="nav-link js-close-account-sidebar"
									target="_blank"
									>
									<img
										src="../assets/images/btn_hover.png"
										class="-img"
										width="100"
										height="100"
										style="display: initial;"
									/>
									<div class="-text" style="font-size:12px;">ติดต่อแอดมิน</div>
									</a>
								</li>
								</ul>
							</div>
							</div>
							<div class="-overlay"></div>
						</div>
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>
			</nav>
			


		<div class="x-modal modal" id="accountModal" tabindex="-1" role="dialog" data-loading-container=".modal-body" data-container="#accountModal" aria-hidden="true">
			<div class="modal-dialog -modal-size  -modal-big -modal-main-account" role="document" style="padding-top: 74.8012px;">
			<div class="modal-content -modal-content">
				<button type="button" class="close f-1 " data-dismiss="modal" aria-label="Close">
					<i class="fas fa-times"></i>
				</button>
				<div class="modal-body -modal-body" style="top: 0px;">
					<div class="x-modal-account-menu">
						<ul class="navbar-nav">
							<!-- <li class="nav-item -account-profile" v-if="!user.userId">
								<button type="button" class="nav-link js-close-account-sidebar" @click="lineLogin()">
									<img class="img-fluid -icon-image" src="../assets/images/icon-line.svg" alt="Line" width="28" height="27">
									<span class="-text-menu" style="font-size:15px">
										เข้าสู่ระบบด้วยไลน์
									</span>
								</button>
							</li> -->
							<li class="nav-item -account-profile active">
								<button type="button" class="nav-link js-close-account-sidebar" data-container="#accountModal" data-active-menu="-account-profile" data-loading="_onLoading_" data-target=".js-profile-account-modal">
									<img class="img-fluid -icon-image" src="../assets/images/shiba888-ic-menu-user.png" alt="icon user" width="28" height="27">
									<span class="-text-menu" style="font-size:15px">
										ข้อมูลบัญชี
									</span>
								</button>
							</li>
							<!-- <li class="nav-item -account-provider ">
								<button type="button" class="nav-link js-close-account-sidebar" data-container="#accountModal" data-active-menu="-account-provider" data-loading="_onLoading_" data-target=".js-profile-account-modal">
									<img class="img-fluid -icon-image" src="../assets/images/shiba888-ic-menu-provider.png" alt="icon phone" width="28" height="27">
									<span class="-text-menu">
										เข้าเล่นผ่านแอพ
									</span>
								</button>
							</li> -->
										<!-- <li class="nav-item -coupon ">
									<button type="button" class="nav-link js-close-account-sidebar js-account-approve-aware" data-container="#accountModal" data-active-menu="-coupon" data-loading="_onLoading_" data-target=".js-profile-account-modal">
										<img class="img-fluid -icon-image" src="../assets/images/shiba888-ic-menu-coupon.png" alt="icon coupon" width="28" height="27">
										<span class="-text-menu">
											ใช้คูปอง
										</span>
									</button>
								</li> -->
									<li class="nav-item -join-promotion ">
								<button type="button" class="nav-link js-close-account-sidebar" data-container="#accountModal" data-active-menu="-join-promotion" data-loading="_onLoading_" data-target=".js-profile-account-modal" @click="gotoPromotion()">
									<img class="img-fluid -icon-image" src="../assets/images/shiba888-ic-menu-promotion.png" alt="icon promotion" width="28" height="27">
									<span class="-text-menu" style="font-size:15px">
										โปรโมชั่น
									</span>
								</button>
							</li>

							
							<li class="nav-item js-close-account-sidebar -logout">
								<a href="javascript:void(0)" class="nav-link js-require-confirm" data-title="ต้องการออกจากระบบ ?" @click="confirmLogout()">
									<img class="img-fluid -icon-image" src="../assets/images/shiba888-ic-menu-logout.png" alt="icon logout" width="28" height="27">
									<span class="-text-menu" style="font-size:15px">
										ออกจากระบบ
									</span>
								</a>
							</li>
						</ul>
					</div>
					<div class="js-profile-account-modal -layout-account">
						<div class="x-account-profile -v2">
							<div data-animatable="fadeInModal" class="-profile-container animated fadeInModal">
							<div class="x-title-modal -v2 mx-auto text-center">
								ข้อมูลบัญชี
							</div>
				
							<div class="text-center">
								<div class="my-3">
									<div class="x-profile-image">
										<img class="img-fluid -profile-image" :src="getLevelImage2()" alt="customer image" style="width: 120px;">
									</div>
							</div>

							<div class="my-3">
								<div class="-text-username">Username:  {{user.tel}}</div>
									<a href="#0" class="-link-change-password" data-toggle="collapse" data-target=".js-change-password-collapse"><u style="color:#fff">เปลี่ยนรหัสผ่าน</u></a>
								</div>

								<div class="collapse -change-password-container js-change-password-collapse">
									<div class="js-collapse-content">

									<div class="form-group mt-3">
										<input type="password" v-model="password" required="required" placeholder="รหัสผ่านปัจจุบัน" class="x-form-control form-control">
									</div>
									<div class="form-group">
										<input type="password" v-model="password1" required="required" placeholder="รหัสผ่านใหม่" class="x-form-control form-control">
									</div>
									<div class="form-group">
										<input type="password" v-model="password2" required="required" placeholder="พิมพ์รหัสผ่านใหม่อีกครั้ง" class="x-form-control form-control">
									</div>

									<button type="button" @click="changePassword()" class="btn -submit btn-primary text-center m-auto js-user-confirm-deposit">
										<span>ยืนยัน</span>
									</button>

									</div>
								</div>
							</div>

							<div class="-bank-info-container">
								<div class="x-customer-bank-info-container -v2">
									<div class="media m-auto">
										<img v-if="user.bank" :src="require('../assets/images/bank/' + user.bank + '.jpg')" class="-img rounded-circle" width="50" height="50" alt="bank-scb">
										<div class="-content-wrapper">
											<span class="-name">{{user.firstname}} {{user.lastname}}</span>
											<span class="-number">{{user.account_no}}</span>
										</div>
									</div>
								</div>
							</div>

					
							<div class="x-admin-contact text-center ">
								<span class="x-text-with-link-component">
									<label class="-text-message ">พบปัญหา</label>
									<a href="https://lin.ee/Tos0Vpg" class="-link-message " target="_blank" rel="noopener">
										<u>ติดต่อฝ่ายบริการลูกค้า</u>
									</a>
								</span>
							</div>

							<div class="js-has-info"></div>
						</div>
					</div>
				</div>
			</div>
			</div>
			</div>
		</div>


		<div class="x-modal modal show" id="accountModalMobile" tabindex="-1" role="dialog" data-loading-container=".modal-body" data-container="#accountModalMobile" aria-modal="true">
		<div class="modal-dialog -modal-size  -modal-mobile -account-modal -no-fixed-button" role="document" style="padding-top: 60px;">
			<div class="modal-content -modal-content">
				<button type="button" class="close f-1 " data-dismiss="modal" aria-label="Close">
					<i class="fas fa-times"></i>
				</button>
				<div class="modal-header -modal-header">
					<div class="x-modal-mobile-header">
						<div class="-header-mobile-container">
							<h3 class="x-title-modal mx-auto text-center d-inline-block">
								ข้อมูลบัญชี
							</h3>
						</div>
					</div>
				</div>
				<div class="modal-body -modal-body" style="top: 76px;">
					<div class="x-account-profile -v2">
						<div data-animatable="fadeInModal" class="-profile-container animated fadeInModal">
							<div class="text-center">
								<div class="my-3">
									<div class="x-profile-image">
										<img class="img-fluid -profile-image" :src="getLevelImage2()" alt="customer image">
									</div>
								</div>

								<div class="my-3">
									<div class="-text-username">Username:  {{user.tel}}</div>
									<a href="#0" class="-link-change-password" data-toggle="collapse" data-target=".js-change-password-collapse"><u style="color:#fff">เปลี่ยนรหัสผ่าน</u></a>
								</div>

								<div class="-change-password-container js-change-password-collapse collapse" style="">
									<div class="js-collapse-content">

											<div class="form-group mt-3">
												<input type="password" v-model="password" required="required" placeholder="รหัสผ่านปัจจุบัน" class="x-form-control form-control">
											</div>
											<div class="form-group">
												<input type="password" v-model="password1" required="required" placeholder="รหัสผ่านใหม่" class="x-form-control form-control">
											</div>
											<div class="form-group">
												<input type="password" v-model="password2" required="required" placeholder="พิมพ์รหัสผ่านใหม่อีกครั้ง" class="x-form-control form-control">
											</div>

											<button type="button" @click="changePassword()" class="btn btn-block -submit">
												<span>ยืนยัน</span>
											</button>
									</div>
								</div>
							</div>

							<div class="-bank-info-container">
								<div class="x-customer-bank-info-container -v2">
									<div class="media m-auto">
										<img v-if="user.bank" :src="require('../assets/images/bank/' + user.bank + '.jpg')" class="-img rounded-circle" width="50" height="50" alt="bank-scb">
										<div class="-content-wrapper">
											<span class="-name">{{user.firstname}} {{user.lastname}}</span>
											<span class="-number">{{user.account_no}}</span>
										</div>
									</div>
								</div>
							</div>
							<div class="x-admin-contact text-center ">
								<span class="x-text-with-link-component">
								<label class="-text-message ">พบปัญหา</label>
								<a href="https://lin.ee/Tos0Vpg" class="-link-message " target="_blank" rel="noopener">
									<u>ติดต่อฝ่ายบริการลูกค้า</u>
								</a>
								</span>
							</div>

							<div class="js-has-info"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		</div>


		<div
			class="x-modal modal -v2 -with-half-size"
			id="withdrawModal"
			tabindex="-1"
			role="dialog"
			aria-hidden="true"
			data-loading-container=".modal-body"
			data-container="#withdrawModal"
     	 >
<div class="modal-dialog -modal-size " role="document">
  <div class="modal-content -modal-content">
    <button
      type="button"
      class="close f-1 "
      data-dismiss="modal"
      aria-label="Close"
    >
      <i class="fas fa-times"></i>
    </button>
    <div class="modal-header -modal-header">
      <h3 class="x-title-modal -v2 m-auto">
ถอนเงิน
      </h3>
    </div>
    <div class="modal-body -modal-body" style="top: 0px;height: 350px;max-height: 350px;overflow-y: auto;">
      <div class="x-withdraw-form -v2">
  <div
    data-animatable="fadeInModal"
    class="-animatable-container animated fadeInModal"
  >
    <div class="text-center d-flex flex-column">
      <div
class="-x-input-icon x-input-operator mb-3 flex-column"
      >
<button
  type="button"
  class="-icon-left -btn-icon js-adjust-amount-by-operator"
  @click="setWithdraw('minus')"
>
  <i class="fas fa-minus-circle"></i>
</button>
<input
  type="text"
  v-model="withdraw"
  :class="{'x-form-control':true,'text-center':true, 'js-withdraw-input-amount':true ,'-no':true,'form-control':true,'is-invalid':(error)?true:false}"
  placeholder="จำนวนเงินที่ต้องการถอน"
  @keypress="isNumber($event)"

/>
						<div class="invalid-feedback " v-if="error">
							<ul class="list-unstyled mb-0"><li>{{error}}</li></ul>
						</div>
<button
  type="button"
  class="-icon-right -btn-icon js-adjust-amount-by-operator"
  @click="setWithdraw('plus')"
>
  <i class="fas fa-plus-circle"></i>
</button>
      </div>
					<!-- <div  v-if="lastbonus.canwithdraw || lastbonus.turncredit">
					<p class="glow" style="font-size:14px;">
						{{lastbonus.canwithdraw}}/{{lastbonus.turncredit}}
					</p>
					</div> -->
      <div
class="x-select-amount js-quick-amount -v2"
data-target-input="#withdraw_amount"
      >
<div class="-amount-container">
  <button
    type="button"
    class="btn btn-block -btn-select-amount"
    @click="setWithdraw(200)"
  >
    <span class="-no" >200</span>
  </button>
</div>
<div class="-amount-container">
  <button
    type="button"
    class="btn btn-block -btn-select-amount"
    @click="setWithdraw(300)"
  >
    <span class="-no" >300</span>
  </button>
</div>
<div class="-amount-container">
  <button
    type="button"
    class="btn btn-block -btn-select-amount"
    @click="setWithdraw(500)"
  >
    <span class="-no" >500</span>
  </button>
</div>
<div class="-amount-container">
  <button
    type="button"
    class="btn btn-block -btn-select-amount"
    @click="setWithdraw(1000)"
  >
    <span class="-no" >1000</span>
  </button>
</div>
<div class="-amount-container">
  <button
    type="button"
    class="btn btn-block -btn-select-amount"
    @click="setWithdraw(2000)"
  >
    <span class="-no" >2000</span>
  </button>
</div>
<div class="-amount-container">
  <button
    type="button"
    class="btn btn-block -btn-select-amount"
    @click="setWithdraw(5000)"
  >
    <span class="-no" >5000</span>
  </button>
</div>
      </div>
    </div>
	<!-- <div><p class="-title" style="font-family: 'Kanit', sans-serif; font-size: 12px; padding-bottom:10px;color:#fff">- ถอนขั้นต่ำ 100.- สูงสุด 100,000.- /ครั้ง</p></div>
	<div><p class="-title" style="font-family: 'Kanit', sans-serif; font-size: 12px; padding-bottom:10px;color:#fff">- สูงสุด 300,000.-และ 5 ครั้ง/วัน</p></div>
	<div><p class="-title" style="font-family: 'Kanit', sans-serif; font-size: 12px; padding-bottom:10px;color:#fff">- ระบบธนาคารปิดปรับปรุง โปรดเลี่ยงการแจ้งถอนในช่วงเวลา <br>23:50-00:10น.</p></div> -->
	<div class="mt-3 -spacer">
		<div class="x-deposit-bank-info-container -v2">
		<div class="media m-auto">
			<img
			v-if="user.bank" :src="require('../assets/images/bank/' + user.bank + '.jpg')"
			class="-img rounded-circle"
			width="50"
			height="50"
			alt="bank-bay"
			/>
			<div class="-content-wrapper">
			<span class="-own-name"
				>{{user.firstname}} {{user.lastname}}</span
			>
			<span id="depositBankNumber" class="-number"
				>{{user.account_no}}</span
			>
			</div>
			<div class="-copy-wrapper">
			<a
				href="javascript:void(0);"
				style="cursor: default;"
				class="btn -btn js-copy-to-clipboard"
				>เข้าบัญชีนี้</a
			>
			</div>
		</div>
		</div>
	</div>
    <div class="text-center">
      <button
type="button"
class="btn -submit btn-primary my-0 my-lg-3 f-5 f-lg-6"
						@click="withdrawConfirm()"
      >
{{this.canwithdraw}}
      </button>
    </div>
  </div>

  <input
    type="hidden"
    id="withdraw__token"
    name="withdraw[_token]"
    value="bllExR5-OWRJU6oF0VySYE94ynEFuTfZGWIxep2iqUo"
  />
      </div>
    </div>
  </div>
</div>
      	</div>

		<div
			class="x-modal modal -v2 -with-backdrop -with-separator -with-half-size"
			id="depositModal"
			tabindex="-1"
			role="dialog"
			aria-hidden="true"
			data-loading-container=".modal-body"
			data-container="#depositModal"
      >
<div class="modal-dialog -modal-size " role="document">
  <div class="modal-content -modal-content">
    <div class="modal-content -modal-content">
      <button
		type="button"
		class="close f-1 "
		data-dismiss="modal"
		aria-label="Close"
      >
<i class="fas fa-times"></i>
      </button>
      <div class="modal-body -modal-body" style="top: 0px;">
<div class="x-pending -v2 ">
  <div
    class="js-content text-center -animated-wrapper animated fadeInModal"
    data-animatable="fadeInModal"
  >
    <div class="-fake-bg-top-wrapper">
      <h3
class="x-title-modal -v2 mx-auto text-center d-inline-block"
      >
ฝากเงิน
      </h3>
    </div>
    <div class="-fake-bg-bottom-wrapper">
      <div class="x-modal-separator-container ">
<div class="-top " v-if="!corepaypage">
  <div v-if="showbank">
    <div class="mt-3 -spacer" v-if="bank.kbank && bank.kbank.accountno && user && user.bank !=='kbank' && user.bank !== 'truewallet' && bank.bank">
      <div class="x-deposit-bank-info-container -v2">
        <div class="media m-auto">
          <img
            src="../assets/images/bank/kbank.jpg"
            class="-img rounded-circle"
            width="50"
            height="50"
            alt="bank-bay"
          />
          <div class="-content-wrapper">
            <span class="-name">ธนาคารกสิกร</span>
            <span class="-own-name">{{bank.kbank.firstname}} {{bank.kbank.lastname}}</span>
            <span id="depositBankNumber" class="-number">{{bank.kbank.accountno}}</span>
          </div>
          <div class="-copy-wrapper">
            <a
              href="javascript:void(0);"
              class="btn -btn js-copy-to-clipboard"
              v-clipboard:copy="bank.kbank.accountno.toString().replace(/-/g,'')"
              v-clipboard:success="clipboardSuccessHandler"
            >คัดลอก</a>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3 -spacer" v-if="bank && bank.scb && bank.bank && bank.scb.accountno && user.bank !== 'truewallet'">
      <div class="x-deposit-bank-info-container -v2">
        <div class="media m-auto">
          <img
            src="../assets/images/bank/scb.jpg"
            class="-img rounded-circle"
            width="50"
            height="50"
            alt="bank-bay"
          />
          <div class="-content-wrapper">
            <span class="-name">ธนาคารไทยพาณิชย์</span>
            <span class="-own-name">{{bank.scb.firstname}} {{bank.scb.lastname}}</span>
            <span id="depositBankNumber" class="-number">{{bank.scb.accountno}}</span>
          </div>
          <div class="-copy-wrapper">
            <a
              href="javascript:void(0);"
              class="btn -btn js-copy-to-clipboard"
              v-clipboard:copy="bank.scb.accountno.replace(/-/g,'')"
              v-clipboard:success="clipboardSuccessHandler"
            >คัดลอก</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="bank && user && user.bank !== 'truewallet'">
    <div class="x-deposit-bank-info-container -v2" style="background-color: transparent;">
      <div class="media m-auto">
        <div
          data-animatable="fadeInModal"
          class="-animatable-container animated fadeInModal" style="width:80%"
        >
          <div class="text-center d-flex flex-column" style="bottom: 30px;">
            <div class="-x-input-icon x-input-operator mb-3 flex-column">
              <button
                type="button"
                class="-icon-left -btn-icon js-adjust-amount-by-operator"
                @click="setWithdraw2('minus')"
              >
                <i class="fas fa-minus-circle"></i>
              </button>
              <input
                type="text"
                v-model="deposit_value"
                :class="{'x-form-control':true,'text-center':true, 'js-withdraw-input-amount':true ,'-no':true,'form-control':true,'is-invalid':(error)?true:false}"
                placeholder="จำนวนเงินที่ต้องการฝาก"
                @keypress="isNumber($event)"
              />
              <div class="invalid-feedback " v-if="error">
                <ul class="list-unstyled mb-0"><li>{{error}}</li></ul>
              </div>
              <button
                type="button"
                class="-icon-right -btn-icon js-adjust-amount-by-operator"
                @click="setWithdraw2('plus')"
              >
                <i class="fas fa-plus-circle"></i>
              </button>
            </div>
            <div
              class="x-select-amount js-quick-amount -v2"
              data-target-input="#withdraw_amount"
            >
              <div class="-amount-container">
                <button
                  type="button"
                  class="btn btn-block -btn-select-amount"
                  @click="setWithdraw2(100)"
                >
                  <span class="-no">100</span>
                </button>
              </div>
              <div class="-amount-container">
                <button
                  type="button"
                  class="btn btn-block -btn-select-amount"
                  @click="setWithdraw2(300)"
                >
                  <span class="-no">300</span>
                </button>
              </div>
              <div class="-amount-container">
                <button
                  type="button"
                  class="btn btn-block -btn-select-amount"
                  @click="setWithdraw2(500)"
                >
                  <span class="-no">500</span>
                </button>
              </div>
              <div class="-amount-container">
                <button
                  type="button"
                  class="btn btn-block -btn-select-amount"
                  @click="setWithdraw2(1000)"
                >
                  <span class="-no">1000</span>
                </button>
              </div>
              <div class="-amount-container">
                <button
                  type="button"
                  class="btn btn-block -btn-select-amount"
                  @click="setWithdraw2(2000)"
                >
                  <span class="-no">2000</span>
                </button>
              </div>
              <div class="-amount-container">
                <button
                  type="button"
                  class="btn btn-block -btn-select-amount"
                  @click="setWithdraw2(5000)"
                >
                  <span class="-no">5000</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <p class="glow" style="font-size:14px;">
        พิมพ์ใส่ยอดที่ต้องการฝากหรือกดเลือกยอดตามที่แสดงได้เลยค่ะ
      </p>
    </div>
  </div>
  <div class="mt-3 -spacer" v-else-if="bank && bank.truewallet && user && user.bank === 'truewallet'">
    <div class="x-deposit-bank-info-container -v2">
      <div class="media m-auto">
        <img
          src="../assets/images/bank/tmw.png"
          class="-img rounded-circle"
          width="50"
          height="50"
          alt="bank-bay"
        />
        <div class="-content-wrapper">
          <span class="-name">ทรูมันนี่ วอลเล็ท</span>
          <span class="-own-name">{{bank.truewallet.firstname}}  {{bank.truewallet.lastname}}</span>
          <span id="depositBankNumber2" class="-number">{{bank.truewallet.mobile}}</span>
        </div>
        <div class="-copy-wrapper">
          <a
            href="javascript:void(0);"
            v-clipboard:copy="bank.truewallet.mobile.replace(/-/g,'')"
            v-clipboard:success="clipboardSuccessHandler"
            class="btn -btn js-copy-to-clipboard"
          >คัดลอก</a>
        </div>
      </div>
    </div>
  </div>
  <div class="-spacer">
    <div class="x-danger-zone -v2 ">
      <span>กรุณาใช้เลขบัญชีที่สมัครโอนเข้ามาเท่านั้น</span>
    </div>
  </div>
  <div class="-spacer">
    <hr class="-liner">
  </div>
  <div class="x-deposit-promotion-outer-container js-scroll-ltr -fade -on-left -on-right" v-if="promotions && promotions.name">
    <div>
      <p class="-title" style="font-family: 'Kanit', sans-serif; font-size: 12px; padding-bottom:10px;color:#fff">หากต้องการรับโปร โปรดกดรับโปรทุกครั้งก่อนโอนเงินนะคะ</p>
    </div>
    <div class="x-deposit-promotion -v2 -slide pt-0" data-scroll-booster-container=".x-deposit-promotion-outer-container" data-scroll-booster-content=".x-deposit-promotion" style="transform: translate(0px, 0px);">
      <div :class="{'-promotion-box-wrapper':true,'-active':this.gotpro}" @click="receivePromotion()">
        <button type="button" :class="{'btn -promotion-box-apply-btn js-promotion-apply':true,'-active':this.gotpro}">
          <img src="../assets/images/ic-gift.png" class="-img" :alt="promotions.name" width="26" height="26">
          <span class="-title" style="font-size: 12px;">{{promotions.name}}</span>
        </button>
        <div class="mid">
          <label class="rocker rocker-small" style="box-sizing: inherit;margin:0;padding:0;">
            <input type="checkbox" v-model="this.gotpro" @click="receivePromotion()">
            <span class="switch-left">รับ</span>
            <span class="switch-right">ไม่รับ</span>
          </label>
        </div>
        <!-- <a href="javascript:void(0)" :class="{'-promotion-box-cancel-btn js-cancel-promotion':true,'-show':this.gotpro}">
          <i class="fas fa-times"></i>
        </a> -->
      </div>
      <div class="-promotion-box-wrapper -active" style="font-family: Kanit, sans-serif;min-width: 65%;background: transparent;align-items: flex-start;justify-content: flex-start;" v-if="promotions.detail">
        <span style="font-size:16px">รายละเอียด</span>
        <span style="font-size:13px;text-align: left;" v-html="nl2br(promotions.detail)"></span>
      </div>
      <!-- <div class="-promotion-box-wrapper -active">
        <button type="button" class="btn -promotion-box-apply-btn js-promotion-apply -active" data-url="/promotion/27/apply" data-type="deposit" data-display-slide-mode="true">
          <img src="../assets/images/ic-gift.png" class="-img" alt="รับเพิ่ม 2% ทุกบิลฝาก" width="26" height="26">
          <span class="-title" style="font-size: 12px;">รับเพิ่ม 2% ทุกบิลฝาก</span>
        </button>
        <a href="javascript:void(0)" class="-promotion-box-cancel-btn js-cancel-promotion -show" data-url="/promotion-active/cancel" data-display-slide-mode="true">
          <i class="fas fa-times"></i>
        </a>
      </div> -->
    </div>
  </div>
  <div>
    <p class="-description" style="font-family: 'Kanit', sans-serif;">ไม่รับโบนัส ถอนได้ทันที เพียงมียอดเล่นเท่าที่ฝาก</p>
  </div>
  <div class="-spacer" style="cursor:pointer" @click="openBrowseFile()">
    <input type="file" id="my_file" accept="image/*" @change="uploadSlip">
    <div class="x-danger-zone -v2" style="background:#f48d1f;color:#fff;">
      <span style="font-size:15px;">หากเงินไม่เข้าภายใน 5 นาที อัพโหลดสลิปที่นี่!!</span>
    </div>
  </div>
</div>
<div class="-top" v-else-if="corepayimage">
	<iframe :src="corepayimage" width="100%" style="height:90vh;"></iframe>
</div>
<div class="-top" v-else-if="corepaymessage">
	{{corepaymessage}}
</div>
<div class="-top" style="padding-bottom: 30px;" v-else-if="corepaypage">
  
</div>

<div class="-bottom">
  <div class="my-3 -spacer">
    <button
      v-if="(!corepaypage && !corepayimage) && !showbank"
      type="button"
      class="btn -submit btn-primary text-center m-auto js-user-confirm-deposit"
      @click="checkDeposit()"
    >
      ยืนยันการฝาก
    </button>
    <div v-if="(!corepaypage || corepayimage) && !showbank" class="my-3 -spacer">&nbsp;</div>
    <button
	  v-if="(showbank || corepayimage)"
      type="button"
      class="btn -submit btn-primary text-center m-auto js-user-confirm-deposit"
	  @click="closeDeposit()"
    >
      ปิดหน้าต่าง
    </button>
  </div>
</div>
      </div>
    </div>
  </div>
</div>
      </div>
    </div>
  </div>
</div>
      	</div>

<!--Anoucement-->
<div id="open-modal" class="modal-window">
  <div>
    <a href="#" id="an-close" title="Close" style="display:none" class="modal-close">Close</a>
    <h1>{{announcement_user.title}}</h1>
    <div v-html="nl2br(announcement_user.detail)" style="font-size:15px;font-family: 'Kanit', sans-serif;"></div>
	<font size="2" style="font-family: 'Kanit', sans-serif;">{{showDateTime(announcement_user.createdate)}}</font>
    <div style=" display: flex; justify-content: space-between;padding-top:20px;">
		<button style="border: 2px solid #1e1eff;background-color: #000;color:#fff; border-radius: 50px;font-family: 'Kanit', sans-serif;" @click="closeAnnouncement(1)">รับทราบแล้ว</button>
		<button style="border: 2px solid #1e1eff;background-color: #000;color:#fff; border-radius: 50px;font-family: 'Kanit', sans-serif;" @click="closeAnnouncement(2)">ข้ามไปก่อน</button>
	</div>
  </div>
</div>
<!-- Promotion -->
		  <div data-loading-container=".modal-body" data-container=".promotion-detail-modal-27" data-ajax-modal-always-reload="true" tabindex="-1" class="modal x-modal -promotion-detail-modal promotion-detail-modal-27 show" aria-modal="true" :style="`display: ${(promotion && promotion.name)?`block`:'none'}; padding-right: 8px;`"><div class="modal-dialog -modal-size  " role="document" style="padding-top: 152.8px;">
    <div class="modal-content -modal-content" :style="{ 'background-image': `url(${require('../assets/images/Shiba-BG.jpg')})`}">
                    <button type="button" class="close f-1 " data-dismiss="modal" aria-label="Close" @click="closePromotion()">
                <i class="fas fa-times"></i>
            </button>
                                <div class="modal-body -modal-body" style="top: 0px;">
                            
        <div class="container">
            <div class="row">
                <div class="col-12 mt-4">
                    <div class="x-page-title-component ">
    <div class="-inner-wrapper">
        <h1 class="-title">โปรโมชั่น</h1>
            </div>
</div>
                </div>
            </div>
        </div>

        <div class="d-flex flex-column">
                            <div class="-real-content">
                    
    <div class="x-card card -card-promotion-detail ">
                                            
            <div class="card-body">
                <div class="-img-container">
                    <img :src="promotion.image" :alt="promotion.name" class="-img-promotion img-fluid">
                </div>


                <div class="x-promotion-content"><h2>{{promotion.name}}</h2>

<p><br>
<div v-html="nl2br(promotion.detail)" style="font-size:15px;font-family: 'Kanit', sans-serif;">
</div>
<br>
<!-- <a href="/term-and-condition">เงื่อนไขและกติกาพื้นฐานจะถูกนำมาใช้กับโปรโมชั่นนี้</a> -->
</p></div>
            </div>

                            <div class="card-footer">
                    <!-- <button class="btn -btn -get-promotion-btn js-promotion-apply " data-toggle="modal" data-target="#depositModal" data-promotion-target="promotion-detail-modal-27" data-type="deposit" data-dismiss="modal" data-url="/promotion/27/apply">
                        <span>รับโปรโมชั่น</span>
                    </button> -->
                </div>
                        </div>
                </div>
            
                                                        
                                    <div class="mx-3 mb-3">
                        <div class="x-admin-contact -no-fixed">
                            <span class="x-text-with-link-component" style="font-size:15px;font-family: 'Kanit', sans-serif;">
     <label class="-text-message ">ติดปัญหา&nbsp;</label>&nbsp;
     <a href="https://lin.ee/Tos0Vpg" class="-link-message " target="_blank" rel="noopener">
        <u>ติดต่อฝ่ายบริการลูกค้า</u>
    </a>
</span>
                        </div>
                    </div>
                                    </div>
            </div>
    </div>
</div></div>
    <a class="btn" href="#open-modal" id="a-modal" style="display: none;">👋 Basic CSS-Only Modal</a>
	</header>
</template>
<script>
import queryString from 'querystring'
import firebase from 'firebase/app'
import 'firebase/database'
import router from './../router'
import moment from 'moment'
const audio = new Audio(require('../assets/money.mp3'))
const audio2 = new Audio(require('../assets/moneyout.mp3'))
const axios = require('axios').default
const qs = require('qs')
export default {
  name: "Header",
  mounted () {
   	const user = localStorage.getItem('userData');
	if(user){
		const _user = JSON.parse(user);
		if(!_user.lastlogin || _user.lastlogin !== moment().tz('Asia/Bangkok').format('YYYY-MM-DD')){
			_user.lastlogin = moment().tz('Asia/Bangkok').format('YYYY-MM-DD')
			localStorage.setItem('userData', JSON.stringify(_user))
			this.addLogin(_user)
		}
		$('#depositModal').on('hidden.bs.modal',  (e)=> {
          this.corepaypage = false;
          this.corepayimage = '';
		  this.showbank = false;
		  this.deposit_value = '';
          if(this.unsubscribe){
            this.unsubscribe()
          }
        });
		this.getAnnouncementUser(_user.key);
	  }else{
		firebase.auth().signOut()
		localStorage.removeItem('userData')
		router.replace('/login')
	  }
  },
  async created(){
	  this.getBankDeposit();
	  //$("#depositChoosePromotionModal").modal('show')
	const user = localStorage.getItem('userData');
	if(user){
		const _user = JSON.parse(user);
		this.$store.dispatch('user/setUser',_user).catch(err => { console.error(err) });
	}else{
		firebase.auth().signOut()
		localStorage.removeItem('userData')
		router.replace('/login')
	}
	const { code } = queryString.parse(window.location.search.replace('?', ''))
	if (!code) return
	const result = await this.getToken(code)
	if (result && result.access_token) {
		const { data } = await this.getProfile(result.access_token)
		this.checkLineLogin(data)
	}
  },
  data() {
    return {
		showbank:false,
		corepaymessage:'',
		unsubscribe:null,
		corepayimage:'',
		deposit_value:'',
		corepaypage:false,
		corepay: false,
		papaya: false,
		corepaymain: false,
		papayamain: false,
		announcement_user:{
			close:false,
			createdate:'',
			detail:'',
			title:'',
		},
		password:'',
		password1:'',
		password2:'',
		fa_spin:false,
		accountOpen:false,
		withdraw: '',
		error: '',
		copyfield:'',
		canwithdraw:'ยืนยัน',
		clientId: '1656935750',
		callbackUri: 'https://deltaplus.com',
		clientSecret: '4b4c6d35256b0034244f1a0056ddb640',
		bank:{
			scb:{
				firstname:'',
				lastname:'',
				accountno:'',
			},
			truewallet:{
				firstname:'',
				lastname:'',
				mobile:'',
			}
		},
    }
  },
  watch: {
    // whenever question changes, this function will run
    creditincrese(newQuestion, oldQuestion) {
		if(newQuestion){
			audio.volume = 0.5;
			audio.play();
			this.$store.commit("user/SET_CREDIT_INCREASE", false) 
		}
    },
	creditdecrese(newQuestion, oldQuestion) {
		if(newQuestion){
			audio2.volume = 0.5;
			audio2.play();
			this.$store.commit("user/SET_CREDIT_DECREASE", false) 
		}
    },
	
	alertwithdraw(newalert, oldwithdraw) {
		if(newalert){
			if(Number(this.lastbonus.turncredit) <= Number(this.user.credit)){
				if(Number(this.lastbonus.canwithdraw) > 0 && Number(this.user.credit) >= Number(this.lastbonus.canwithdraw)){
					$("#withdrawModal").modal('hide')
					this.$swal({ icon: 'question', title: 'แจ้งถอน', html: `คุณทำเทิร์นครบแล้ว! จะถอนได้ ${this.lastbonus.canwithdraw} บาท<br> กดปุ่มยืนยันการถอนได้เลยค่ะ`,showCancelButton: true,confirmButtonText: 'ยืนยัน'}).then((result) => {
						if(result.isConfirmed){
							this.withdraw = this.user.credit;
							this.withdrawConfirm();
						}
					});
				}else{
					$("#withdrawModal").modal('hide')
					this.$swal({ icon: 'question', title: 'แจ้งถอน', text: `คุณทำเทิร์นครบแล้ว! กดปุ่มยืนยันการถอนได้เลยค่ะ`,showCancelButton: true,confirmButtonText: 'ยืนยัน'}).then((result) => {
						if(result.isConfirmed){
							this.withdraw = this.user.credit;
							this.withdrawConfirm();
						}
					});
				}
			}else if(Number(this.user.credit)>5){
				$("#withdrawModal").modal('hide')
				this.$swal({ icon: 'error', title: 'แจ้งถอน', text: `คุณต้องทำยอด ${this.lastbonus.turncredit} ค่ะ`}).then((result) => {
				});
			}
			this.$store.commit("user/SET_ALERT_WITHDRAWALL", false) 
		}
	}
  },
  computed: {
	user(){
		return this.$store.state.user.user;
    },
	creditincrese(){
		return this.$store.state.user.creditincrese;
	},
	creditdecrese(){
		return this.$store.state.user.creditdecrese;
	},
	lastbonus () {
      return this.$store.state.user.lastbonus;
    },
	alertwithdraw (){
		return this.$store.state.user.alertwithdraw;
	},
	promotions (){
		return this.$store.state.user.promotions;
	},
	gotpro (){
		return this.$store.state.user.gotpro;
	},
	promotion(){
		return this.$store.state.user.promotion;
	},
	loading () {
      return this.$store.state.user.loading
    }
  },
  methods: {
	setWithdraw2 (value) {
		  if (value === 'plus') {
			  this.deposit_value = (this.deposit_value) ? this.deposit_value + 10 : 10
		  } else if (value === 'minus') {
			  if (this.deposit_value) { this.deposit_value = (this.deposit_value) ? this.deposit_value - 10 : 0 }
		  } else if (value > 0) { this.deposit_value = value } else { this.deposit_value = 0 }
	},
	closeDeposit(){
		$("#depositModal").modal('hide')
	},
	checkDeposit(){
		if(Number(this.deposit_value)>0){
			if((Number(this.deposit_value) < 300) || !this.papaya){
				this.showbank = true;
			}else if(this.papaya){
				this.corepaypage = true;
				this.genQrCodeCorePay();
			}
		}
	},
	genQrCodeCorePay(){
		if(this.papayamain && this.corepaymain){
			if(this.deposit_value < 300) this.papaya = false; else this.corepay = false;
		}
		if(this.papaya){
			if(this.deposit_value < 300){
				this.$swal({ icon: 'error', title: 'แจ้งฝากพร้อมเพย์', text: `จำนวนเงินต้องมากกว่า 300 บาท`}).then((result) => {});
			}else{
				this.$store.commit('user/SET_LOADING', true)
				firebase.firestore().collection('papaya').add({
					userkey:this.user.key,
					code:this.user.code,
					createdate: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss'),
					status:false,
					amount:this.deposit_value,
				}).then(async (result)=>{
					this.unsubscribe = firebase.firestore().collection('papaya').doc(result.id).onSnapshot(async (doc) => {
					if(doc.data().papaya && doc.data().status){
						this.corepayimage = doc.data().papaya.payurl;
						this.unsubscribe()
					}else if(doc.data().papaya && !doc.data().status){
						this.$store.commit('user/SET_LOADING', false)
						if(doc.data().papaya)
						this.$swal({ icon: 'error', title: 'แจ้งฝากพร้อมเพย์มีปัญหา', text: '\nโปรดติดต่อ admin'}).then((result) => {
						});
						this.unsubscribe()
					}else if(doc.data().papaya && doc.data().status){
						this.$store.commit('user/SET_LOADING', false)
						this.$swal({ icon: 'success', title: 'Success', text: `แจ้งฝากพร้อมเพย์เรียบร้อย`})
						this.unsubscribe()
						$("#depositModal").modal('hide')
					}
					});
					axios.get(`https://api.deltaplus-dp.com/papaya/${result.id}`).then((result)=>{
					this.$store.commit('user/SET_LOADING', false)
					})
				})
			}
		}else
		if(this.deposit_value >= 50){
			this.$store.commit('user/SET_LOADING', true)
			firebase.firestore().collection('corepay').add({
			userkey:this.user.key,
			code:this.user.code,
			createdate: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss'),
			status:false,
			amount:this.deposit_value,
			}).then(async (result)=>{
			this.unsubscribe = firebase.firestore().collection('corepay').doc(result.id).onSnapshot(async (doc) => {
				if(doc.data().corepay && doc.data().status){
				this.corepayimage = doc.data().corepay.redirect;
				this.unsubscribe()
				}else if(doc.data().corepay && !doc.data().status){
				this.$store.commit('user/SET_LOADING', false)
				if(doc.data().corepay)
				this.$swal({ icon: 'error', title: 'แจ้งฝากพร้อมเพย์มีปัญหา', text: doc.data().corepay.message+'\nไม่สามารถฝากซ้ำได้โปรดรอ 5 นาทีแล้วแจ้งฝากใหม่'}).then((result) => {
				});
				this.unsubscribe()
				}
			});
			axios.get(`https://api.deltaplus-dp.com/api/repay/${result.id}`).then((result)=>{
				this.$store.commit('user/SET_LOADING', false)
			})
			
			
			// this.$swal({ icon: 'success', title: 'แจ้งฝากพร้อมเพย์เรียบร้อย', text: `ทีมงานขออภัยในความล่าช้าและจะรีบดำเนินการให้โดยเร็วที่สุด`}).then((result) => {
			// });
			})
		}else{
			this.$swal({ icon: 'error', title: 'แจ้งฝากพร้อมเพย์', text: `จำนวนเงินต้องมากกว่า 50 บาท`}).then((result) => {
			});
		}
	  this.papaya = this.papayamain
	  this.corepay = this.corepaymain
      //this.corepaypage = false;
    },
    showCorepayPage(){
      if(this.corepaypage)
        this.corepaypage = false;
      else
        this.corepaypage = true;
    },
	getLevelImage(){
		if(this.user.level){
			return require(`../assets/images/level/shiba-head-level${this.user.level}.png`)
		}else{
			return require('../assets/images/level/shiba-head-level1.png')
		}
	},
	getLevelImage2(){
		if(this.user.level){
			return require(`../assets/images/level/shiba-body-level${this.user.level}.png`)
		}else{
			return require('../assets/images/level/shiba-body-level1.png')
		}
	},
	showDateTime(date){
		return moment(date,'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY HH:mm');
	},
	async closeAnnouncement(type){
		if(type===1){
			firebase.database().ref(`/announcement_users/${this.user.key}`).update({
				status:'รับทราบแล้ว',
				updatetime:moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss'),
			});
		}
		document.getElementById("an-close").click();
	},
	async getAnnouncementUser(key){
		if(key){
			const announcement = (await firebase.database().ref(`/announcement_users/${key}`).once('value')).val();
			if(announcement && !announcement.close && announcement.title && (!announcement.status || announcement.status!=='รับทราบแล้ว')){
				this.announcement_user = announcement;
				document.getElementById("a-modal").click();
			}
		}
	},
	async uploadSlip(event){
		const imageData = event.target.files[0];
		this.$store.commit('user/SET_LOADING', true)
		await firebase.firestore().collection('slip').where('userkey','==',this.user.key).where('status','==','กำลังดำเนินการ').get().then((result)=>{
			if(result.empty){
				firebase.firestore().collection('slip').add({
					userkey:this.user.key,
					code:this.user.code,
					user:{
						code:this.user.code,
						firstname:this.user.firstname,
						lastname:this.user.lastname,
						bank:this.user.bank,
						tel:this.user.tel,
						account_no:this.user.account_no,
						bonus:this.user.bonus,
					},
					createdate: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss'),
					status:'กำลังดำเนินการ',
				}).then(async (result)=>{
					const imagetype = imageData.name.split('.');
					const storageRef = firebase.storage().ref(`images/slip/${result.id}.${imagetype[imagetype.length-1]}`).put(imageData);
					await storageRef.on(`state_changed`,snapshot=>{
						this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
					}, error=>{console.log(error.message)},
					()=>{
						storageRef.snapshot.ref.getDownloadURL().then((url)=>{
							firebase.firestore().collection('slip').doc(result.id).update({
								image:url,
							})
						});
					});
					this.$store.commit('user/SET_LOADING', false)
					this.$swal({ icon: 'success', title: 'แจ้งสลิปเรียบร้อย', text: `ทีมงานขออภัยในความล่าช้าและจะรีบดำเนินการให้โดยเร็วที่สุด`}).then((result) => {
					});
				})
			}else{
				this.$store.commit('user/SET_LOADING', false)
				this.$swal({ icon: 'error', title: 'แจ้งสลิป', text: `ทีมงานกำลังตรวจสอบสลิปของท่านก่อนหน้าและจะรีบดำเนินการให้โดยเร็วที่สุด`}).then((result) => {
				});
			}
		});
		
	},
	openBrowseFile(){
    	document.getElementById('my_file').click();
	},
	  async getBankDeposit(){
		setTimeout(async () => {
			this.bank = (await firebase.database().ref(`/bank/deposit`).once('value')).val();
			if(this.bank.corepay){
				this.corepay = true;
				this.corepaymain = true;
			}else{
				this.corepay = false;
				this.corepaymain = false;
			}
			if(this.bank.papaya){
				this.papaya = true;
				this.papayamain = true;
			}else{
				this.papaya = false;
				this.papayamain = false;
			}
			if(this.user && (!this.user.deposittotal || Number(this.user.deposittotal) <= 10000)){
				this.bank.scb = this.bank.scb4
				this.bank.kbank = undefined
			}else
			if(this.user && this.bank.scb2.accountno && Number(this.user.account_no) % 2 === 0){
				if(this.bank.scb5.accountno && (this.user.account_no.toString().charAt(this.user.account_no.toString().length-1) === '0' || this.user.account_no.toString().charAt(this.user.account_no.toString().length-1) === '2')){
					this.bank.scb = this.bank.scb5;
				}else{
					this.bank.scb = this.bank.scb2;
				}
			}else if(this.user.account_no.toString().charAt(this.user.account_no.toString().length-1) === '1' || this.user.account_no.toString().charAt(this.user.account_no.toString().length-1) === '3'){
				this.bank.scb = this.bank.scb3;
			}
		}, 1500);
	},
	isNumber(evt){
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) || charCode === 46) {
            evt.preventDefault();
        } else {
            return true;
        }
    },
	checkLineLogin (data) {
		if(data.userId && this.user && this.user.key){
	  		firebase.database().ref(`users/${this.user.key}`).update({userId:data.userId,pictureUrl:data.pictureUrl});
			this.$swal({ icon: 'success', title: 'Line', text: 'เชื่อมต่อระบบ Line เรียบร้อยแล้วค่ะ' })
			const user = localStorage.getItem('userData');
			if(user){
				const _user = JSON.parse(user);
				this.$store.dispatch('user/setUser',_user).catch(err => { console.error(err) });
			}
		}
    },
	async getToken (code) {
      const data = qs.stringify({
        grant_type: 'authorization_code',
        code,
        redirect_uri: this.callbackUri,
        client_id: this.clientId,
        client_secret: this.clientSecret
      })
      let response
      await axios({
        method: 'post',
        url: 'https://api.line.me/oauth2/v2.1/token',
        data: data
      }).then(async res => {
        if (res.status !== 200) {
          this.error = 'โปรดติดต่อแอดมิน'
        } else if (res.data) {
          response = res.data
        }
      })
      return response
    },
	async getProfile (token) {
      return await axios.get('https://api.line.me/v2/profile', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    },
	lineLogin () {
      const url = 'https://access.line.me/oauth2/v2.1/authorize'
      let params = {
        response_type: 'code',
        client_id: this.clientId,
        redirect_uri: this.callbackUri,
        state: Math.random().toString(32).substring(2),
        scope: 'openid profile'
      }
      if (this.addFriend) {
        params = Object.assign(params, {
          prompt: 'consent',
          bot_prompt: 'aggressive'
        })
      }
      window.location.href = `${url}?${queryString.stringify(params)}`
    },
	async addLogin(user){
        await axios.get('https://api.ipify.org?format=json').then(x => {
          if(x.data.ip && user.key){
			axios({
				method: 'post',
				url: 'https://api.deltaplus-dp.com/api/addlogin',
				data: {
					key: user.key,
					ip: x.data.ip
				}
			})
          }
        });
        // await axios.get('https://checkip.amazonaws.com').then(x => {
        //   if(x){
        //     firebase.database().ref(`/users/${key}`).update({
        //       ip:x
        //     });
        //   }
        // });
	},
	nl2br (str, is_xhtml) {
		if (typeof str === 'undefined' || str === null) {
			return '';
		}
		var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
		return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
	},
	closePromotion(){
		this.$store.commit('user/SET_PROMOTION', [])
	},
	clipboardSuccessHandler () {
      this.$swal({ icon: 'success', title: 'Copy', text: 'คัดลอกเรียบร้อยแล้ว' })
    },
	gotoMain(){
		router.replace('/')
	},
	receivePromotion(){
		if(this.user && this.user.key){
			if(this.gotpro){
				this.$store.commit("user/SET_GOT_PRO", false);
				//firebase.database().ref(`users/${this.user.key}`).update({bonus:'N'});
				firebase.firestore().collection('users').doc(this.user.key).update({bonus:'N'});
			}else{
				this.$store.commit("user/SET_GOT_PRO", true);
				//firebase.database().ref(`users/${this.user.key}`).update({bonus:'Y'});
				firebase.firestore().collection('users').doc(this.user.key).update({bonus:'Y'});
			}
		}
	},
	getUserId(){
	  	const user = localStorage.getItem('userData');
		if(user){
			const _user = JSON.parse(user);
			return _user;
		}else{
			firebase.auth().signOut()
			localStorage.removeItem('userData')
			router.replace('/login')
		}
	},
	async moveCreditToWallet(){
		this.$store.commit('user/SET_LOADING', true)
		const user = this.getUserId();
		try {
        // Dispatch the action to move credit to wallet
			await this.$store.dispatch('user/moveCreditToWallet');
			
			// Wait for 2 seconds before proceeding
			//await new Promise(resolve => setTimeout(resolve, 2000));

			// Dispatch the action to get the last bonus
			await this.$store.dispatch('user/getLastBonus', user);

			if (this.lastbonus.turncredit) {
				this.$store.commit("user/SET_ALERT_WITHDRAWALL", true);
			}

			// Wait for an additional 2 seconds
			// Finally, stop the loading
			this.$store.commit('user/SET_LOADING', false);
		} catch (err) {
			console.error(err);
		}
		
		// this.$store.dispatch('user/moveCreditToWallet').finally(res => {
		// 	setTimeout(() => {
		// 		this.$store.dispatch('user/getLastBonus', user).finally(res => {
		// 			this.$store.commit('user/SET_LOADING', false)
		// 			if(this.lastbonus.turncredit){
		// 				this.$store.commit("user/SET_ALERT_WITHDRAWALL", true);
		// 			}
		// 		}).catch(err => { console.error(err) })
		// 	}, 1000);
		// }).catch(err => { console.error(err) })
	},
	async checkPromotions(){
	  this.$store.commit('user/SET_LOADING', true)
      this.$store.dispatch('user/checkPromotions').finally(res => { this.$store.commit('user/SET_LOADING', false) }).catch(err => { console.error(err) })
    },
	getBank(){
		if(this.user.bank){
			require(`../assets/images/${this.user.bank}.jpg`)
		}
	},
	numberWithCommas (x) {
	  if(!x) x = 0;
      return x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
	numberWithCommas2 (x) {
	  if(!x) x = 0;
      return x.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  	},
	gotoPromotion(){
		$("#accountModal").modal('hide')
		router.replace('/promotion')
	},
	confirmLogout(){
      this.$swal({ icon: 'question', title: 'Log Out!', text: 'ยืนยันออกจากระบบ',showCancelButton: true,confirmButtonText: 'ตกลง'}).then((result) => {
		if (result.isConfirmed) {
			$("#accountModal").modal('hide')
			firebase.auth().signOut()
			localStorage.removeItem('userData')
			router.replace('/login')
		}
      })
    },
	setWithdraw (value) {
		  if (value === 'plus') {
			  this.withdraw = (this.withdraw) ? this.withdraw + 10 : 10
		  } else if (value === 'minus') {
			  if (this.withdraw) { this.withdraw = (this.withdraw) ? this.withdraw - 10 : 0 }
		  } else if (value > 0) { this.withdraw = value } else { this.withdraw = 0 }
	},
	rand () {
    	return Math.random().toString(36).substr(2); // remove `0.`
	},
	token () {
    	return this.rand() + this.rand(); // to make it longer
	},
	async withdrawConfirm () {
		const user = this.getUserId();
		if (user) {
			if (this.withdraw && this.withdraw >= 200) {
				if (Number(this.withdraw) <= Number(this.user.credit)) {
					let check = false;
					await firebase.firestore().collection('history').where('userkey','==',user.key).where('status','==','กำลังดำเนินการ').where('type','==','ถอน').limit(1).get().then((result)=>{
						if(result.size){
							check = true;
						}
					});
					if(!check){
						// let total = 0;
						// await firebase.firestore().collection('history').where('userkey','==',user.key).where('status','==','ดำเนินการแล้ว').where('type','==','ถอน').where('createdate','>=',moment().tz('Asia/Bangkok').format('YYYY-MM-DD')+' 00:00:00').get().then((result)=>{
						// 	if(result.size >= 5){
						// 		check = true;
						// 	}
						// 	result.forEach((doc)=>{
						// 		total += Number(doc.data().qty);
						// 	})
						// });
						axios({
							method: 'post',
							url: 'https://api.deltaplus-dp.com/quserwithdraw/userwithdraw',
							data: {
								key: user.key,
								tel: user.tel,
								token: this.token(),
								credit: Number(this.withdraw)
							}
						})
						this.$swal({ icon: 'success', title: 'ทำรายการถอน', text: 'เรียบร้อยแล้วกรุณารอเงินเข้าบัญชี' })
						$("#withdrawModal").modal('hide')
						
					}else{
						this.$swal({ icon: 'error', title: 'ทำรายการถอน', text: 'โปรดรออนุมัติรายการถอนก่อนหน้านี้' })
					}
				}else {
					this.$swal({ icon: 'error', title: 'Error!', text: 'โปรดตรวจสอบจำนวนเครดิต' })
				}
			}else{
				this.$swal({ icon: 'error', title: 'Error!', text: 'โปรดตรวจสอบจำนวนเครดิต' })
			}
		}
	},
    reloadBalance() {
      this.fa_spin = true;
	  const user = localStorage.getItem('userData');
	  if(user){
		  const _user = JSON.parse(user);
		  this.$store.dispatch('user/setUser',_user).finally(() => { 
			  setTimeout(() => {
				  this.fa_spin = false
			  }, 1000);
		  }).catch(err => { console.error(err) });
	  }else{
		firebase.auth().signOut()
		localStorage.removeItem('userData')
		router.replace('/login')
	  }
    },
	showTabAccount() {
      if(!this.accountOpen) this.accountOpen = true; else this.accountOpen = false;
    },
	async changePassword(){
		if(this.password && this.password1 && this.password2){
			if(this.password1.length<6){
				this.$swal({ icon: 'warning', title: 'Password', text: `โปรดระบุรหัสผ่านมากกว่า 6 หลักค่ะ`}).then((result) => {
				});
				return;
			}else if(this.password1 != this.password2){
				this.$swal({ icon: 'warning', title: 'Password', text: `โปรดตรวจสอบรหัสผ่านใหม่อีกครั้งค่ะ`}).then((result) => {
				});
				return;
			}
			const user = this.getUserId();
			firebase.auth().signInWithEmailAndPassword(user.tel.trim().toLowerCase() + '@deltaplus.com', this.password.trim()).then(async (res) => {
				if (res.user) {
					this.$store.commit('user/SET_LOADING', true)
					axios({
						method: 'post',
						url: 'https://api.deltaplus-dp.com/api/resetpassword',
						data: {
							key: user.key,
							password: this.password1
						}
					}).then(async (result)=>{
						if(result.data && result.data.status){
							await firebase.auth().signInWithEmailAndPassword(user.tel.trim().toLowerCase() + '@deltaplus.com', this.password1.trim()).then(async (res2) => {
								if (res2.user) {
									this.$swal({ icon: 'success', title: 'Password', text: `เปลี่ยนรหัสผ่านเรียบร้อยแล้วค่ะ`}).then((result) => {});
									this.password = '';
									this.password1 = '';
									this.password2 = '';
									this.$store.commit('user/SET_LOADING', false)
								}
							}).catch(() => {
							})
						}
					}).catch(()=>{
						this.$store.commit('user/SET_LOADING', false)
					})
				}
			}).catch(() => {
				this.$swal({ icon: 'warning', title: 'Password', text: `โปรดตรวจสอบรหัสผ่านเดิมค่ะ`}).then((result) => {
				});
			})
			// const password = (await firebase.database().ref(`/users/${user.key}/password`).once('value')).val();
			// if(password != this.password){
			// 	this.$swal({ icon: 'warning', title: 'Password', text: `โปรดตรวจสอบรหัสผ่านเดิมค่ะ`}).then((result) => {
			// 	});
			// 	return;
			// }
			// this.$store.commit('user/SET_LOADING', true)
			// axios({
			// 	method: 'post',
			// 	url: 'https://api.deltaplus-dp.com/api/resetpassword',
			// 	data: {
			// 		key: user.key,
			// 		password: this.password1
			// 	}
			// }).then(async (result)=>{
			// 	if(result.data && result.data.status){
			// 		await firebase.auth().signInWithEmailAndPassword(user.tel.trim().toLowerCase() + '@deltaplus.com', this.password1.trim()).then(async (res2) => {
			// 			if (res2.user) {
			// 				this.$swal({ icon: 'success', title: 'Password', text: `เปลี่ยนรหัสผ่านเรียบร้อยแล้วค่ะ`}).then((result) => {
			// 			});
			// 			}
			// 		}).catch(() => {
			// 		})
			// 	}
			// 	this.$store.commit('user/SET_LOADING', false)
			// }).catch(()=>{
			// 	this.$store.commit('user/SET_LOADING', false)
			// })
			$("#accountModalMobile").modal('hide')
			$("#accountModal").modal('hide')
		}
	}
  }
}
</script>
<style scoped>
.glow {
    color: #fff;
    text-align: center;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
    }

    @-webkit-keyframes glow {
    from {
text-shadow: 0 0 5px #dbc47a, 0 0 20px #dbc47a, 0 0 30px #dbc47a, 0 0 40px #dbc47a, 0 0 5px #dbc47a, 0 0 5px #dbc47a, 0 0 5px #dbc47a;
    }
    
    to {
text-shadow: 0 0 5px #dbc47a, 0 0 20px #dbc47a, 0 0 30px #dbc47a, 0 0 40px #dbc47a, 0 0 5px #dbc47a, 0 0 5px #dbc47a, 0 0 5px #dbc47a;
    }
}
@media (max-width: 500px) { 
	.x-header #headerBrand .navbar-brand .-logo {
		width: 350px;
	}
}
.-balance-container .-user-balance .-inner-box-wrapper2 {
    position: relative;
    margin-right: 5px;
    margin-left: 15px;
    padding: 6px 24px 6px 0px;
    font-weight: 500;
    border-radius: 20px;
    color: #fff;
    text-align: right;
}
.x-logged .-profile-container .-btn-wrapper .-profile-name2 {
	font-family: 'Kanit', sans-serif;
    font-size: 1.2rem;
  /*  color: #e5e5e5; */
    font-weight: 200;
    margin-right: 2px;
    padding-left: 10px;
    color: #f9f9d6;
	text-align: center;
}
.x-logged .-profile-container .-btn-mobile-wrapper .-profile-name2 {
	font-family: 'Kanit', sans-serif;
    font-size: 1.1rem;
  /*  color: #e5e5e5; */
    font-weight: 200;
    margin-right: 2px;
    padding-left: 10px;
    color: #f9f9d6;
	text-align: center;
}

.-balance-container .-user-balance .-btn-balance2 {
    position: absolute;
    right: 10px;
    padding: 0;
    color: #FFFFFF;
    background: transparent;
    border: none;
}
.cCsozd {
    display: inline-block;
    padding: 0.5rem 1rem;
    height: 44px;
    font-size: 1em;
    line-height: 1.5;
    border: none;
    border-radius: 0.25rem;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
}
.ewHin {
    background: rgb(0, 187, 0);
    color: rgb(255, 255, 255);
    height: 50px;
    min-width: 180px;
    border-radius: 25px;
    padding-left: 34px;
    padding-right: 34px;
}
.ewHin .icon {
    height: 18px;
    margin: 0px 0.5rem;
    vertical-align: sub;
    border: 0px;
}



.mid {
  display: flex;
  align-items: center;
  justify-content: center;
}


/* Switch starts here */
.rocker {
  display: inline-block;
  position: relative;
  /*
  SIZE OF SWITCH
  ==============
  All sizes are in em - therefore
  changing the font-size here
  will change the size of the switch.
  See .rocker-small below as example.
  */
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: #888;
  width: 7em;
  height: 4em;
  overflow: hidden;
  border-bottom: 0.5em solid #eee;
}

.rocker-small {
  font-size: 0.75em; /* Sizes the switch */
  margin: 1em;
}

.rocker::before {
  content: "";
  position: absolute;
  top: 0.5em;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #999;
  border: 0.5em solid #eee;
  border-bottom: 0;
}

.rocker input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-left,
.switch-right {
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5em;
  width: 3em;
  transition: 0.2s;
}

.switch-left {
  height: 2.4em;
  width: 2.75em;
  left: 0.85em;
  bottom: 0.4em;
  background-color: #ddd;
  transform: rotate(15deg) skewX(15deg);
}

.switch-right {
  right: 0.5em;
  bottom: 0;
  background-color: #bd5757;
  color: #fff;
}

.switch-left::before,
.switch-right::before {
  content: "";
  position: absolute;
  width: 0.4em;
  height: 2.45em;
  bottom: -0.45em;
  background-color: #ccc;
  transform: skewY(-65deg);
}

.switch-left::before {
  left: -0.4em;
}

.switch-right::before {
  right: -0.375em;
  background-color: transparent;
  transform: skewY(65deg);
}

input:checked + .switch-left {
  background-color: #0084d0;
  color: #fff;
  bottom: 0px;
  left: 0.5em;
  height: 2.5em;
  width: 3em;
  transform: rotate(0deg) skewX(0deg);
}

input:checked + .switch-left::before {
  background-color: transparent;
  width: 3.0833em;
}

input:checked + .switch-left + .switch-right {
  background-color: #ddd;
  color: #888;
  bottom: 0.4em;
  right: 0.8em;
  height: 2.4em;
  width: 2.75em;
  transform: rotate(-15deg) skewX(-15deg);
}

input:checked + .switch-left + .switch-right::before {
  background-color: #ccc;
}

/* Keyboard Users */
input:focus + .switch-left {
  color: #333;
}

input:checked:focus + .switch-left {
  color: #fff;
}

input:focus + .switch-left + .switch-right {
  color: #fff;
}

input:checked:focus + .switch-left + .switch-right {
  color: #333;
}
#my_file {
    display: none;
}

</style>
<style lang="scss" scoped>
.modal-window {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.25);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
  
  &:target {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }
  & > div {
    width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1em;
    background: black;
	border: 4px solid #1e1eff;
  	border-radius: 30px;
  }
  header {
    font-weight: bold;
  }
  h1 {
    font-size: 150%;
    margin: 0 0 15px;
  }
}

.modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
  &:hover {
    color: black;
  }
}

/* Demo Styles */
.modal-window {
  & > div {
    border-radius: 1rem;
  }
}

.modal-window div:not(:last-of-type) {
  margin-bottom: 15px;
}

small {
  color: lightgray;
}

.tags {
  list-style: none;
  margin: 0;
  overflow: hidden; 
  padding: 0;
}



.tag {
  background: #eee;
  border-radius: 3px 0 0 3px;
  color: #999;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
}

.tag::before {
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: '';
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}

.tag::after {
  background: #381D83;
  border-bottom: 13px solid transparent;
  border-left: 10px solid #eee;
  border-top: 13px solid transparent;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
}

.tag:hover {
  background-color: #381D83;
  color: white;
}

.tag:hover::after {
   border-left-color: #381D83; 
}



.tag2 {
  background: #eee;
  border-radius: 3px 0 0 3px;
  color: #999;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
}

.tag2::before {
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: '';
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}

.tag2::after {
  background: #381D83;
  border-bottom: 13px solid transparent;
  border-left: 10px solid #eee;
  border-top: 13px solid transparent;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
}

.tag2:hover {
  background-color: #381D83;
  color: white;
}

.tag2:hover::after {
   border-left-color: #381D83; 
}
.x-ranking-entrance{position:absolute;right:0;top:150px;z-index:6;margin-right: -14px;}
@media (max-width:1199.98px){
	.x-ranking-entrance{/*position:relative;top:unset;z-index:unset;display:flex*/}
}
.x-ranking-entrance .-link-wrapper{
	background-color:rgba(0,0,0,.5);border-top-left-radius:20px;border-bottom-left-radius:20px;display:flex;padding:.4rem .75rem;color:#fff;align-items: center;
}
@media (min-width:1200px){
	.x-ranking-entrance .-link-wrapper:hover{text-decoration:none;background-color:rgba(0,0,0,.4)}
}@media (max-width:1199.98px){
	.x-ranking-entrance .-link-wrapper{padding:5px;margin-right:.5rem}
}@media (max-width:575.98px){
	.x-ranking-entrance .-link-wrapper{margin-right:.25rem}
}
.x-ranking-entrance .-link-wrapper .-image-wrapper{
	align-items:center;display:flex
}
.x-ranking-entrance .-link-wrapper .-image-wrapper .-ic-right-star-rank{width:30px}
@media (max-width:370px){
	.x-ranking-entrance .-link-wrapper .-image-wrapper .-ic-right-star-rank{min-width:25px}
}
.x-ranking-entrance .-link-wrapper .-text-wrapper{
	line-height:16px;font-size:1.1rem;margin-left:.5rem;
	display: block;
}
</style>